import Spinner from "@/components/common/spinner";
import { useRouter } from "next/router";
import React, { useEffect } from "react";

export interface IIndexPageProps {}

const IndexPage: React.FC<IIndexPageProps> = () => {
  const router = useRouter();

  useEffect(() => {
    router.replace("/v2");
  }, [router]);

  return <Spinner />;
};

export default IndexPage;
